import * as amplitude from "@amplitude/analytics-browser";
import { LoadingButton } from "@mui/lab";
import { Link, Typography } from "@mui/material";
import { useNavigation, useSubmit } from "@remix-run/react";
import { redirect } from "@remix-run/router";
import type { ActionFunctionArgs } from "@remix-run/server-runtime";
import { withZod } from "@remix-validated-form/with-zod";
import { Auth } from "aws-amplify";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, ValidatedForm } from "remix-validated-form";
import { z } from "zod";

import { Card } from "~/components/card.tsx";
import { PasswordField } from "~/components/inputs/password-field.tsx";
import { ValidatedTextField } from "~/components/validated-form/validated-text-field.tsx";
import { LOGIN_ERROR_MESSAGES } from "~/constants/global.ts";
import SmartCubeLogo from "~/images/smart-cube-360-logo-with-text.svg?url";
import { authenticateUser } from "~/services/auth.server.ts";
import { getStartRedirectUrl } from "~/services/device.server.ts";
import { log } from "~/utils/general.ts";
import { trimmedEmail } from "~/utils/zod-validators.ts";
import { assert } from "~shared/assert.ts";

export async function action({ request }: ActionFunctionArgs) {
	const { email } = await authenticateUser(request);
	const redirectUrl = await getStartRedirectUrl(email);
	return redirect(redirectUrl);
}

export const loginSchema = z.object({
	email: trimmedEmail,
	password: z
		.string({ required_error: "pleaseFillField" })
		.min(1, "pleaseFillField"),
});

export const validator = withZod(loginSchema);

export default function Login() {
	const [apiError, setApiError] = useState("");

	return (
		<ValidatedForm
			noValidate
			validator={validator}
			className="flex h-full w-full overflow-auto"
			onChange={() => {
				setApiError("");
			}}
		>
			<div className="m-auto w-full max-w-md">
				<Card className="m-2 w-full flex-col items-center gap-6 px-4 py-6">
					<img
						className="mb-6"
						width="190"
						height="120"
						src={SmartCubeLogo}
						alt="smart cube logo"
					/>
					<Form apiError={apiError} setApiError={setApiError} />
				</Card>
			</div>
		</ValidatedForm>
	);
}

const Form = ({
	apiError,
	setApiError,
}: {
	apiError: string;
	setApiError: (apiError: string) => void;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const submit = useSubmit();
	const navigation = useNavigation();
	const { getValues, validate } = useFormContext();
	const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
	const appIsBusy = navigation.state !== "idle" && hasBeenSubmitted;

	const handleLogin = async () => {
		const validation = await validate();
		if (validation.error) {
			return;
		}

		setHasBeenSubmitted(true);
		setIsLoading(true);
		setApiError("");
		const formData = getValues();

		const email = formData.get("email") as string | null;
		assert(email, "email is required");

		const password = formData.get("password") as string | null;
		assert(password, "password is required");

		try {
			const parsedEmail = email.toLowerCase().trim();
			await Auth.signIn(parsedEmail, password);
			amplitude.setUserId(parsedEmail);
			submit(null, { method: "POST", action: "/login" });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error instanceof Error) {
				const errorMessage: string | undefined =
					LOGIN_ERROR_MESSAGES[error.name];
				if (errorMessage) {
					setApiError(errorMessage);
				} else {
					setApiError("unknownError");
					log.error("login", JSON.stringify(error));
				}
			} else {
				setApiError("unknownError");
				log.error("login", JSON.stringify(error));
			}
			setIsLoading(false);
		}
	};

	const handleOnKeyDown = async (
		event: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>,
	) => {
		if (event.key === "Enter") {
			await handleLogin();
		}
	};

	return (
		<>
			<div className="flex w-full flex-col gap-8">
				<ValidatedTextField
					name="email"
					fullWidth
					type="email"
					autoComplete="email"
					labelTranslationKey={"email"}
					onKeyDown={(event) => {
						void handleOnKeyDown(event);
					}}
				/>
				<div className="flex flex-col">
					<PasswordField
						labelTranslationKey={"password"}
						name="password"
						onKeyDown={(event) => {
							void handleOnKeyDown(event);
						}}
					/>
					<Link
						href="/login/forgot-password"
						className="ml-auto mt-1 text-sm text-brand3 no-underline decoration-brand3 hover:underline"
					>
						{t("forgotPassword")}
					</Link>
				</div>
			</div>
			<div className="flex h-10 w-full items-center">
				<AnimatePresence>
					{apiError.length > 0 && hasBeenSubmitted && (
						<motion.div
							className="h-fit w-full"
							variants={errorAnimation}
							initial="hidden"
							animate="visible"
							exit="hidden"
							transition={{ duration: ERROR_ANIMATION_DURATION }}
						>
							<Typography
								className="w-full text-center text-red3"
								variant="body1"
							>
								{t(apiError)}
							</Typography>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			<LoadingButton
				color="primary"
				variant="contained"
				loading={isLoading || appIsBusy}
				fullWidth
				onClick={() => {
					void handleLogin();
				}}
			>
				{t("login")}
			</LoadingButton>
		</>
	);
};

export const errorAnimation = {
	hidden: {
		y: 10,
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
	},
};

export const ERROR_ANIMATION_DURATION = 0.3;
